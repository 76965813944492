import styled, { css } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

interface InputErrorProps {
    alignRight?: boolean
}

export const InputError = styled.span<InputErrorProps>(
    ({ theme, alignRight }) => css`
        display: block;
        position: absolute;
        padding: 0.625rem;
        border-radius: ${theme.typography.border.radius.small};
        font-size: ${theme.typography.font.size.small};
        color: ${theme.colors.white};
        background-color: ${theme.colors.tfwErrorRed};
        z-index: 1;
        top: calc(100% + ${theme.spacing.xxSmall});

        ${alignRight
            ? css`
                  right: -${theme.spacing.xxxSmall}; /* 0.125rem = 2px (1 x input border width) */
              `
            : css`
                  left: -${theme.spacing.xxxSmall}; /* 0.125rem = 2px (1 x input border width) */
              `};

        width: calc(100% + ${theme.spacing.xxSmall}); /* 0.25rem = 4px. (2 x input border width) */
        max-width: 22.5rem; /* 360px */

        /* For the tooltip arrow. */
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 1.25rem;
            height: 1.25rem;
            background-color: ${theme.colors.tfwErrorRed};
            transform: rotate(45deg);
            top: -${theme.spacing.xSmall};

            ${alignRight
                ? css`
                      right: 1.25rem;
                  `
                : css`
                      left: 1.25rem;
                  `};

            z-index: -1; /* Keep the tooltip arrow behind the actual tooltip content. */
        }

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            width: max-content;
        }
    `,
)
