import styled, { css } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const Container = styled.ul(
    ({ theme }) => css`
        list-style-type: none;
        padding: ${theme.spacing.xSmall} 0;
    `,
)

export const HistoryContainer = styled.div`
    padding-top: 0.625rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-top: 0;
    }
`

export const HistoryContainerTitle = styled.div(
    ({ theme }) => css`
        padding: ${theme.spacing.medium} ${theme.spacing.medium} 0;
        font-size: 0.8125rem;
        line-height: ${theme.typography.font.size.medium};
        color: ${theme.colors.tfwBlackOpacity50};
        letter-spacing: 0.0125rem;
    `,
)

interface ItemContainerProps {
    isFocused: boolean
}

export const ItemContainer = styled.li<ItemContainerProps>(
    ({ theme, isFocused }) => css`
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: ${theme.typography.font.size.small};
        line-height: ${theme.typography.font.size.xLarge};
        padding: ${theme.spacing.xSmall} ${theme.spacing.medium};

        transition: background-color 200ms ease-out;

        ${isFocused &&
        css`
            background-color: ${theme.colors.tfwBlack3};
        `};
    `,
)

export const ItemTitle = styled.span(
    ({ theme }) => css`
        margin: 0 ${theme.spacing.xSmall};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
)
