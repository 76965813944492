import { desktopBreakpoint, Header, tabletBreakpoint } from 'react-components'
import styled, { css } from 'styled-components'

import ExternalLink from '../../common/external-link'
import { zIndexScale } from '../../../utils/style-utils'

export const Container = styled(Header)(
    ({ theme }) => css`
        position: sticky;
        top: 0;
        background-color: ${({ theme }) => theme.colors.white};
        z-index: ${zIndexScale.largeTags.large};
        border-top: ${theme.typography.border.radius.xxxSmall} solid #0000001a;
        border-bottom: ${theme.typography.border.radius.xxxSmall} solid #0000001a;
        min-height: 3.5rem;
        padding: 0.375rem ${theme.spacing.medium};
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            min-height: ${theme.layout.large};
            padding: 0.625rem ${theme.spacing.large};
        }

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            min-height: 5rem;
            padding: 0.875rem ${theme.spacing.large};
        }
    `,
)

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Restrict expanding after 1280px. */
    max-width: 80rem;
    margin: 0 auto;
`

export const BackButton = styled.button.attrs({ type: 'button' })`
    padding: 0.3125rem;

    /* Remove the unnecessary height due to button height being greater than content height. */
    /* Read more: https://stackoverflow.com/a/45424887/8158281 */

    & * {
        display: block;
    }
`

export const LogoContainer = styled(ExternalLink)`
    display: none;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: unset;
    }
`

export const LogoImage = styled.img(
    ({ theme }) => css`
        /* Remove the unnecessary height due to button height being greater than content height. */
        /* Read more: https://stackoverflow.com/a/45424887/8158281 */
        display: block;

        /* Provide both width and min-width so as to fix the size of the element when present in a flex container,
        same for height. */
        width: 4.5rem;
        height: ${theme.layout.xSmall};
        min-width: 4.5rem;
        min-height: ${theme.layout.xSmall};

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            width: 7.625rem;
            height: 2.5rem;
            min-width: 7.625rem;
            min-height: 2.5rem;
        }
    `,
)
