import React, { PropsWithChildren, Ref } from 'react'

import { useTranslation } from '../../../../../hooks/locale'
import { LocationInput, LocationPickerWidgetCard, PositionedClearButton } from './widget-styles'
import { LocationPickerIcon, WidgetContainer, WidgetInputContainer } from '../widget-styles'
import { CircledCrossMiniIcon } from '../../../../common/icons'
import { LocationPickerWidgetProps } from '../../../../common/location-picker/types'
import {
    SearchbarLocationPickerPassThroughProps,
    SearchbarLocationPickerRenderedComponentExclusiveProps,
} from './types'
import { InputError } from '../../../../common/input-styles'

type Props<T> = LocationPickerWidgetProps<T> &
    SearchbarLocationPickerPassThroughProps &
    SearchbarLocationPickerRenderedComponentExclusiveProps

const LocationPickerWidget = <T extends {} = {}>({
    isOpen,
    inputRef,
    onClear,
    locationInput,
    onLocationInputChange,
    onLocationInputFocus,
    locationInputPlaceholder,
    error,
    onKeyDown,
    onKeyUp,
    showLocationItems,
    locationItems,
    historyItems,
    children,
}: PropsWithChildren<Props<T>>) => {
    const { t } = useTranslation()

    return (
        <WidgetContainer ref={inputRef as Ref<HTMLInputElement>}>
            <WidgetInputContainer hasError={!!error}>
                <LocationPickerIcon alt={t('mapIconText')} />
                <LocationInput
                    value={locationInput}
                    placeholder={locationInputPlaceholder}
                    onChange={onLocationInputChange}
                    onFocus={onLocationInputFocus}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                />
                {!!locationInput && (
                    <PositionedClearButton onClick={onClear}>
                        <CircledCrossMiniIcon alt={t('clear')} />
                    </PositionedClearButton>
                )}
                {!!error && <InputError>{error}</InputError>}
            </WidgetInputContainer>

            {/* Open the card only when we actually have items to show. */}
            <LocationPickerWidgetCard
                isOpen={isOpen && ((showLocationItems && locationItems.length > 0) || historyItems.length > 0)}
            >
                {children}
            </LocationPickerWidgetCard>
        </WidgetContainer>
    )
}

export default LocationPickerWidget
