import { useCallback, useMemo, useReducer } from 'react'

import { CallbackFunction } from '../common/types'

interface AsyncState<T> {
    data: T | null
    isLoading: boolean
    error: Error | null
}

interface Action<T> {
    type: 'START' | 'SAVE' | 'ERROR'
    payload?: T | Error
}

function _getReducer<T>(initialData: T | null = null) {
    return function (state: AsyncState<T>, action: Action<T>): AsyncState<T> {
        switch (action.type) {
            case 'START':
                return { ...state, isLoading: true, error: null }
            case 'SAVE':
                return { ...state, data: action.payload as T, isLoading: false }
            case 'ERROR':
                return { ...state, data: initialData, isLoading: false, error: action.payload as Error }
            default:
                return state
        }
    }
}

export const useAsyncCallback = <T>(callback: CallbackFunction, initialData: T | null = null) => {
    // Do not pass the `initialData` dependency as we do not want to re-run the hook in case it changes.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const reducer = useMemo(() => _getReducer(initialData), [])

    const [state, dispatch] = useReducer(reducer, { data: initialData, isLoading: false, error: null })

    const startAsync = useCallback(() => dispatch({ type: 'START' }), [dispatch])
    const saveAsync = useCallback((result: T) => dispatch({ type: 'SAVE', payload: result }), [dispatch])
    const errorAsync = useCallback((error: Error) => dispatch({ type: 'ERROR', payload: error }), [dispatch])

    const cb = useCallback(
        async (...args) => {
            try {
                startAsync()
                const result: T = await callback(...args)
                saveAsync(result)
                return result
            } catch (error) {
                errorAsync(error as Error)
                throw error
            }
        },
        [callback, startAsync, saveAsync, errorAsync],
    )

    return { ...state, startAsync, saveAsync, errorAsync, callback: cb }
}
