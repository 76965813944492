import styled, { AnyStyledComponent, css } from 'styled-components'
import { desktopBreakpoint } from 'react-components'

import { WidgetCard } from '../widget-card'
import { CalendarIcon, GuestsIcon, LocationPinIcon } from '../../../common/icons'
import { InputError } from '../../../common/input-styles'

export const WidgetContainer = styled.div`
    position: relative;
`

interface WidgetInputContainerProps {
    hasError?: boolean
}

export const WidgetInputContainer = styled.div<WidgetInputContainerProps>(
    ({ theme, hasError }) => css`
        position: relative;
        display: flex;
        align-items: center;

        border: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};

        ${WidgetContainer}:first-child & {
            border-top-left-radius: ${theme.typography.border.radius.small};
            border-bottom-left-radius: ${theme.typography.border.radius.small};
        }

        ${WidgetContainer}:last-child & {
            border-top-right-radius: ${theme.typography.border.radius.small};
            border-bottom-right-radius: ${theme.typography.border.radius.small};
        }

        ${!!hasError &&
        css`
            border-color: ${theme.colors.tfwErrorRed};

            & *:not(${InputError}) {
                color: ${theme.colors.tfwErrorRed};
                stroke: ${theme.colors.tfwErrorRed};
            }
        `};
    `,
)

export const NonSelectableWidgetInputContainer = styled(WidgetInputContainer)`
    user-select: none;
`

export const WidgetInput = styled.input(
    ({ theme }) => css`
        background-color: transparent;
        padding: ${theme.spacing.xSmall} ${theme.spacing.xSmall} ${theme.spacing.xSmall} 2.25rem;
        width: 100%;
        font-size: ${theme.typography.font.size.small};
        line-height: ${theme.typography.font.size.xLarge};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: ${theme.spacing.small} ${theme.spacing.medium} ${theme.spacing.small} ${theme.spacing.xxLarge};
        }
    `,
)

export const InputIcon = styled.svg(
    ({ theme }) => css`
        position: absolute;
        left: ${theme.spacing.small};

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            left: ${theme.spacing.medium};
        }
    `,
)

export const LocationPickerIcon = styled(InputIcon as AnyStyledComponent).attrs({ as: LocationPinIcon })``
export const DatePickerIcon = styled(InputIcon as AnyStyledComponent).attrs({ as: CalendarIcon })``
export const GuestPickerIcon = styled(InputIcon as AnyStyledComponent).attrs({ as: GuestsIcon })``

export const SearchbarWidgetCard = styled(WidgetCard)(
    ({ theme }) => css`
        top: 100%;
        margin-top: ${theme.spacing.xxSmall};
        border: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        box-shadow: 0 8px 10px #0000000f, 0 3px 14px #0000000a, 0 5px 5px #00000014;
    `,
)
