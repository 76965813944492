import styled, { css } from 'styled-components'

export const LocationInput = styled.input.attrs({ type: 'text', autoComplete: 'false' })(
    ({ theme }) => css`
        border: none;
        padding: 0;
        font-size: ${theme.typography.font.size.small};
        line-height: ${theme.typography.font.size.xLarge};
        margin: 0 ${theme.spacing.xSmall};
        flex-grow: 1;

        &:active,
        &:focus,
        &:focus-visible {
            outline: none;
        }
    `,
)
