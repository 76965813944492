import React, { forwardRef, PropsWithChildren } from 'react'

import { ModalFooterContainer, ModalFooterContainerProps } from './modal-footer-styles'

export type ModalFooterProps = ModalFooterContainerProps

const ModalFooter = forwardRef<HTMLDivElement, PropsWithChildren<ModalFooterProps>>((props, ref) => {
    const { children, ...modalFooterContainerProps } = props

    return (
        <ModalFooterContainer {...modalFooterContainerProps} ref={ref}>
            {children}
        </ModalFooterContainer>
    )
})

ModalFooter.displayName = 'ModalFooter'

export default ModalFooter
