import styled, { css } from 'styled-components'

import { CancelIcon } from '../icons'

export const CloseButton = styled.button.attrs({ type: 'button' })`
    padding: 0.375rem;
`

export const CloseIcon = styled(CancelIcon)`
    /* Remove the unnecessary height due to button height being greater than content height. */
    /* Read more: https://stackoverflow.com/a/45424887/8158281 */
    display: block;
`

export const TextContainer = styled.div(
    ({ theme }) => css`
        margin-left: ${theme.spacing.small};
        line-height: ${theme.typography.font.size.xLarge};
        flex-grow: 1;
    `,
)

export const Title = styled.strong(
    ({ theme }) => css`
        font-size: ${theme.typography.font.size.medium};
    `,
)

export const Subtitle = styled.p(
    ({ theme }) => css`
        font-size: ${theme.typography.font.size.small};
        color: ${theme.colors.tfwBlack38};
    `,
)

export const ClearButton = styled.button.attrs({ type: 'button' })(
    ({ theme }) => css`
        font: ${theme.typography.font.lato.weight.bold} ${theme.typography.font.size.small} /
            ${theme.typography.font.size.xLarge} ${theme.typography.font.family.latoVerdana};
        color: ${theme.colors.tfwBlue12};
    `,
)
