import React, { PropsWithChildren } from 'react'

import { LocationInput } from './modal-styles'
import { ClearButton } from './styles'
import { useTranslation } from '../../../../../hooks/locale'
import { BackIcon, CircledCrossMiniIcon } from '../../../../common/icons'
import { BackButton } from '../../styles'
import FullScreenModal, { FullScreenModalBody } from '../../../../common/modal/full-screen-modal'
import { Container as ModalHeaderContainer, InnerContainer } from '../../../../common/modal/styles'
import { LocationPickerModalProps } from '../../../../common/location-picker/types'
import {
    SearchbarLocationPickerPassThroughProps,
    SearchbarLocationPickerRenderedComponentExclusiveProps,
} from './types'

type Props<T> = LocationPickerModalProps<T> &
    SearchbarLocationPickerPassThroughProps &
    SearchbarLocationPickerRenderedComponentExclusiveProps

const LocationPickerModal = <T extends {} = {}>({
    isOpen,
    onClose,
    onClear,
    locationInput,
    onLocationInputChange,
    onLocationInputFocus,
    locationInputPlaceholder,
    onKeyDown,
    onKeyUp,
    children,
}: PropsWithChildren<Props<T>>) => {
    const { t } = useTranslation()

    return (
        <FullScreenModal isOpen={isOpen} onRequestClose={onClose}>
            <ModalHeaderContainer hasBorderBottom>
                <InnerContainer>
                    <BackButton onClick={onClose}>
                        <BackIcon alt={t('backIconText')} />
                    </BackButton>
                    <LocationInput
                        value={locationInput}
                        placeholder={locationInputPlaceholder}
                        onChange={onLocationInputChange}
                        onFocus={onLocationInputFocus}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                    />
                    {!!locationInput && (
                        <ClearButton onClick={onClear}>
                            <CircledCrossMiniIcon alt={t('clear')} />
                        </ClearButton>
                    )}
                </InnerContainer>
            </ModalHeaderContainer>

            <FullScreenModalBody>{children}</FullScreenModalBody>
        </FullScreenModal>
    )
}

export default LocationPickerModal
