import React, { forwardRef, MouseEventHandler, PropsWithChildren } from 'react'

import { ClearButton, CloseButton, CloseIcon, Subtitle, TextContainer, Title } from './modal-header-styles'
import { useTranslation } from '../../../hooks/locale'
import { Container, ContainerProps, InnerContainer } from './styles'

export interface ModalHeaderProps extends Omit<ContainerProps, 'hasBorderTop'> {
    title: string
    subtitle?: string
    onClose: MouseEventHandler<HTMLButtonElement>
    onClear?: MouseEventHandler<HTMLButtonElement>
}

const ModalHeader = forwardRef<HTMLDivElement, PropsWithChildren<ModalHeaderProps>>((props, ref) => {
    const { title, subtitle, onClose, onClear, children, ...modalHeaderContainerProps } = props

    const { t } = useTranslation()

    return (
        <Container {...modalHeaderContainerProps} ref={ref}>
            <InnerContainer>
                <CloseButton onClick={onClose}>
                    <CloseIcon alt={t('cancelIconText')} />
                </CloseButton>
                <TextContainer>
                    <Title>{title}</Title>
                    {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
                </TextContainer>
                {!!onClear && <ClearButton onClick={onClear}>{t('clear')}</ClearButton>}
            </InnerContainer>
            {children}
        </Container>
    )
})

ModalHeader.displayName = 'ModalHeader'

export default ModalHeader
