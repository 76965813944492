import styled, { css } from 'styled-components'

interface WidgetCardProps {
    isOpen: boolean
}

export const WidgetCard = styled.div<WidgetCardProps>(
    ({ theme, isOpen }) => css`
        position: absolute;
        border-radius: ${theme.typography.border.radius.small};
        background-color: ${theme.colors.white};
        width: max-content;
        overflow: hidden;
        box-shadow: 0 0 7px #00000026;

        /* Use visibility in conjunction with opacity so that we do not listen to any events when component is not
          visible. */
        visibility: hidden;
        opacity: 0;
        transition: visibility 200ms ease-out, opacity 200ms ease-out;

        ${isOpen &&
        css`
            visibility: visible;
            opacity: 1;
        `};
    `,
)
