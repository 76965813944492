import styled, { css } from 'styled-components'

import { Container, ContainerProps } from './styles'

export interface ModalFooterContainerProps extends Omit<ContainerProps, 'hasBorderBottom'> {
    padding?: string
}

export const ModalFooterContainer = styled(Container)<ModalFooterContainerProps>(
    ({ padding, theme }) => css`
        padding: ${padding ?? `${theme.spacing.xSmall} ${theme.spacing.medium}`};
    `,
)
