import styled, { css } from 'styled-components'

import { ItemTitle } from '../../../../common/location-picker/location-picker-items-styles'

export const SuggestionItemTitle = styled(ItemTitle)`
    flex-grow: 1;
`

export const Pill = styled.span(
    ({ theme }) => css`
        padding: ${theme.spacing.xxSmall} ${theme.spacing.xSmall};
        background-color: ${theme.colors.tfwWhite64};
        border-radius: ${theme.typography.border.radius.xSmall};
        font-size: 0.8125rem;
        line-height: ${theme.typography.font.size.medium};
        letter-spacing: 0.0125rem;
        color: ${theme.colors.tfwBlackOpacity50};
        flex-shrink: 0;
    `,
)
