import styled, { css } from 'styled-components'

import { ItemTitle } from './location-picker-items-styles'

export const HistoryItemTitle = styled(ItemTitle)`
    flex-basis: max-content;
    max-width: 45%;
`

export const VerticalSeparator = styled.div(
    ({ theme }) => css`
        height: ${theme.layout.xxSmall};
        border: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
    `,
)

export const ItemDescription = styled.div(
    ({ theme }) => css`
        margin: 0 ${theme.spacing.xSmall};
        font-size: ${theme.typography.font.size.xSmall};
        line-height: ${theme.typography.font.size.medium};
        color: ${theme.colors.tfwBlackOpacity50};
        letter-spacing: 0.0125rem;
        flex: 1 1 min-content;
    `,
)
