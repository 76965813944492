import { ComponentType, Key, KeyboardEventHandler, Ref } from 'react'

import { SearchbarHistoryItem } from '../../../redux/searchbar/types'

export enum LocationPickerMode {
    MODAL = 'modal',
    WIDGET = 'widget',
}

export type OnFocus<T> = (item: T | SearchbarHistoryItem | null) => void
export type OnLocationInputSelect = (input: string) => void
export type OnLocationItemSelect<T> = (item: T) => void
export type OnHistoryItemSelect = (item: SearchbarHistoryItem) => void
export type GetLocationItemKey<T> = (item: T) => Key
export type LocationItemComponentProps<T> = {
    item: T
    onSelect: OnLocationItemSelect<T>
    isFocused: boolean
    onFocus: OnFocus<T>
}

/**
 * Props that are passed to the `LocationPicker` which are common to all sub-components.
 */
export interface LocationPickerCommonProps<T extends {} = {}> {
    showLocationItems: boolean
    locationItems: T[]
    onLocationItemSelect: OnLocationItemSelect<T>
    onHistoryItemSelect: OnHistoryItemSelect
    locationItemComponent: ComponentType<LocationItemComponentProps<T>>
    getLocationItemKey: GetLocationItemKey<T>
}

/**
 * Extra props that are passed to the rendered component (modal/widget) by the location-picker.
 */
export interface LocationPickerRenderedComponentAddedProps<T extends {} = {}> {
    historyItems: SearchbarHistoryItem[]
    focusedItem: T | SearchbarHistoryItem | null
    setFocusedItem: OnFocus<T>
    onKeyDown: KeyboardEventHandler<HTMLInputElement>
}

/**
 * Props that are always passed through components.
 */
export type LocationPickerPassThroughProps = {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    locationInput: string
    onLocationInputSelect: OnLocationInputSelect
    error?: string
    mode?: LocationPickerMode
}

export type LocationPickerModalProps<T> = LocationPickerPassThroughProps &
    LocationPickerCommonProps<T> &
    LocationPickerRenderedComponentAddedProps<T> & {
        [prop: string]: unknown
    }

export type LocationPickerWidgetProps<T> = LocationPickerPassThroughProps &
    LocationPickerCommonProps<T> &
    LocationPickerRenderedComponentAddedProps<T> & {
        inputRef: Ref<HTMLElement>
        [prop: string]: unknown
    }

export type LocationPickerExclusiveProps<T> = {
    mode: LocationPickerMode
    modalComponent: ComponentType<LocationPickerModalProps<T>>
    widgetComponent: ComponentType<LocationPickerWidgetProps<T>>
}

export type LocationPickerProps<T> = LocationPickerPassThroughProps &
    LocationPickerCommonProps<T> &
    LocationPickerExclusiveProps<T> & {
        // For overriding. We always pass a custom `onKeyDown` method.
        onKeyDown?: KeyboardEventHandler<HTMLInputElement>
        [prop: string]: unknown
    }
