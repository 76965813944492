import React from 'react'

import LocationPickerItems from './location-picker-items'
import {
    LocationPickerCommonProps,
    LocationPickerExclusiveProps,
    LocationPickerRenderedComponentAddedProps,
    LocationPickerPassThroughProps,
} from './types'

type Props<T> = LocationPickerPassThroughProps &
    LocationPickerCommonProps<T> &
    LocationPickerRenderedComponentAddedProps<T> & {
        component: LocationPickerExclusiveProps<T>['modalComponent']
        [prop: string]: unknown
    }

const Modal = <T extends {} = {}>({ component: Component, isOpen, onOpen, onClose, ...restProps }: Props<T>) => {
    return (
        <Component {...restProps} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <LocationPickerItems {...restProps} />
        </Component>
    )
}

export default Modal
