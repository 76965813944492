import React, { ComponentType, useContext } from 'react'
import { ScreenContext } from 'react-components'

import LocationPicker from '../../../../common/location-picker'
import {
    LocationPickerModalProps,
    LocationPickerMode,
    LocationPickerWidgetProps,
} from '../../../../common/location-picker/types'
import LocationPickerModal from './modal'
import LocationPickerWidget from './widget'
import { useSearchbarLocationPicker } from './hooks'
import { SearchbarLocationPickerProps } from './types'

const SearchbarLocationPicker = <T extends {} = {}>({
    onLocationInputChange,
    onLocationItemSelect,
    onHistoryItemSelect,
    ...restProps
}: SearchbarLocationPickerProps<T>) => {
    const { onOpen, onClose, locationInput, locationItems, getLocationItems, setLocationItems, mode } = restProps

    const { isMobile } = useContext(ScreenContext)

    const {
        showLocationItems,
        onLocationInputChange: _handleLocationInputChange,
        onLocationInputFocus,
        onLocationItemSelect: _handleLocationItemSelect,
        onHistoryItemSelect: _handleHistoryItemSelect,
        onClear,
    } = useSearchbarLocationPicker({
        onOpen,
        onClose,
        locationInput,
        onLocationInputChange,
        locationItems,
        getLocationItems,
        setLocationItems,
        onLocationItemSelect,
        onHistoryItemSelect,
    })

    return (
        <LocationPicker
            {...restProps}
            mode={mode ? mode : isMobile ? LocationPickerMode.MODAL : LocationPickerMode.WIDGET}
            // TODO: Fix these types. Currently, there's no way to make TypeScript pick the extra props that we are
            //  passing as related just to these components.
            modalComponent={LocationPickerModal as ComponentType<LocationPickerModalProps<T>>}
            widgetComponent={LocationPickerWidget as ComponentType<LocationPickerWidgetProps<T>>}
            showLocationItems={showLocationItems}
            onLocationInputChange={_handleLocationInputChange}
            onLocationInputFocus={onLocationInputFocus}
            onLocationItemSelect={_handleLocationItemSelect}
            onHistoryItemSelect={_handleHistoryItemSelect}
            onClear={onClear}
        />
    )
}

export default SearchbarLocationPicker
