import styled, { css } from 'styled-components'
import { desktopBreakpoint, mobileBreakpoint } from 'react-components'

import { ClearButton } from './styles'
import { SearchbarWidgetCard, WidgetInput } from '../widget-styles'

export const LocationInput = styled(WidgetInput).attrs({ type: 'text', autoComplete: 'false' })(
    ({ theme }) => css`
        padding-right: 1.75rem;

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding-right: ${theme.spacing.xLarge};
        }
    `,
)

export const PositionedClearButton = styled(ClearButton)(
    ({ theme }) => css`
        position: absolute;
        right: ${theme.spacing.xSmall};

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            right: ${theme.spacing.small};
        }
    `,
)

export const LocationPickerWidgetCard = styled(SearchbarWidgetCard)`
    /* Give a min-width as otherwise container collapses due to flex nature of children elements. */
    min-width: 22.5rem; /* 360px */
    left: -0.0625rem; /* 1px */

    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        min-width: unset;
        width: ${({ theme }) => theme.layout.fill};
    }
`
