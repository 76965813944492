export const getPersonsCount = (adults: number | null, children: number | null) => {
    if (!adults && !children) {
        return 0
    } else if (!children) {
        return adults
    } else if (!adults) {
        return children
    } else {
        return adults + children
    }
}
