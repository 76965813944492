import styled, { css } from 'styled-components'

import Modal from '..'
import ModalHeader, { ModalHeaderProps } from '../modal-header'
import ModalFooter, { ModalFooterProps } from '../modal-footer'

const FullScreenModal = styled(Modal)`
    & .Modal {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`

interface FullScreenModalHeaderProps extends ModalHeaderProps {
    zIndex?: number
}

export const FullScreenModalHeader = styled(ModalHeader)<FullScreenModalHeaderProps>(
    ({ zIndex }) => css`
        position: sticky;
        top: 0;
        flex-shrink: 0;
        ${zIndex !== undefined && `z-index: ${zIndex}`};
    `,
)

export const FullScreenModalBody = styled.div`
    flex: 1 0 auto;
`

interface FullScreenModalFooterProps extends ModalFooterProps {
    zIndex?: number
}

export const FullScreenModalFooter = styled(ModalFooter)<FullScreenModalFooterProps>(
    ({ zIndex }) => css`
        position: sticky;
        bottom: 0;
        flex-shrink: 0;
        ${zIndex !== undefined && `z-index: ${zIndex}`};
    `,
)

export default FullScreenModal
