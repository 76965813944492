import { Children, FC, ReactNode } from 'react'

export const nextSlide = (carouselRef: any): void => {
    if (carouselRef && carouselRef.current && carouselRef.current.slickNext) {
        carouselRef.current.slickNext()
    }
}

export const prevSlide = (carouselRef: any): void => {
    if (carouselRef && carouselRef.current && carouselRef.current.slickPrev) {
        carouselRef.current.slickPrev()
    }
}

export const goToSlide = (carouselRef: any, index: number): void => {
    if (carouselRef && carouselRef.current && carouselRef.current.slickPrev) {
        carouselRef.current.slickGoTo(index)
    }
}

/* eslint-disable unicorn/no-fn-reference-in-iterator */
export const getContent = (children: any, element: FC<{}>): ReactNode | null => {
    let content = null
    if (children) {
        Children.forEach(children, child => {
            if (child && child.type === element) {
                content = child && child.props && child.props.children
                return content
            }
        })
    }
    return content
}
/* eslint-enable unicorn/no-fn-reference-in-iterator */

export const getPropertyClickRedirectUrl = (baseUrl: string, href: string) => {
    try {
        return new URL(href)
    } catch {
        return new URL(href, baseUrl)
    }
}

const isUrlAbsolute = (url: string) => {
    try {
        new URL(url)
        return true
    } catch {
        return false
    }
}

export const preventDefaultHandler = (e: Event) => {
    e.preventDefault()
}
