import React from 'react'

import { LocationPickerMode, LocationPickerProps } from './types'
import Modal from './modal'
import Widget from './widget'
import { useLocationPicker } from './hooks'

const LocationPicker = <T extends {} = {}>({
    mode,
    modalComponent,
    widgetComponent,
    onKeyDown: _handleKeyDown,
    ...restProps
}: LocationPickerProps<T>) => {
    const {
        locationInput,
        onLocationInputSelect,
        showLocationItems,
        locationItems,
        onLocationItemSelect,
        onHistoryItemSelect,
    } = restProps

    const { focusedItem, setFocusedItem, historyItems, onKeyDown } = useLocationPicker({
        locationInput,
        onLocationInputSelect,
        showLocationItems,
        locationItems,
        onLocationItemSelect,
        onHistoryItemSelect,
        onKeyDown: _handleKeyDown,
    })

    if (mode === LocationPickerMode.MODAL) {
        return (
            <Modal
                {...restProps}
                component={modalComponent}
                historyItems={historyItems}
                focusedItem={focusedItem}
                setFocusedItem={setFocusedItem}
                onKeyDown={onKeyDown}
            />
        )
    }

    return (
        <Widget
            {...restProps}
            component={widgetComponent}
            historyItems={historyItems}
            focusedItem={focusedItem}
            setFocusedItem={setFocusedItem}
            onKeyDown={onKeyDown}
        />
    )
}

export default LocationPicker
