import React, { FC } from 'react'
import ReactModal, { Props } from 'react-modal'
import styled, { css } from 'styled-components'

type ModalProps = Props & { modalClassName: string }

// Set app element to NextJS' own app element.
ReactModal.setAppElement('#__next')

const CustomModal: FC<ModalProps> = ({ className, modalClassName, children, ...restProps }) => {
    return (
        <ReactModal
            className={modalClassName}
            portalClassName={className as string}
            shouldCloseOnOverlayClick
            {...restProps}
        >
            {children}
        </ReactModal>
    )
}

const Modal = styled(CustomModal).attrs({
    overlayClassName: 'Overlay',
    modalClassName: 'Modal',
})(
    ({ theme }) => css`
        & .Overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10002;
            height: 100%;
        }

        /* For transition effects. More examples are given in react-modal documentation. */
        & .ReactModal__Overlay {
            background-color: transparent;
            transition: background-color 200ms ease-out;
        }

        & .ReactModal__Overlay--after-open {
            background-color: #212121cc; /* Get this from react-components color variable */
        }

        & .ReactModal__Overlay--before-close {
            background-color: transparent;
        }

        & .Modal {
            position: relative;
            background-color: ${theme.colors.white};
            overflow: auto;
            border-radius: ${theme.typography.border.radius.xSmall};
            outline: none;
        }
    `,
)

export default Modal
