import React, { FC, MouseEventHandler, useCallback } from 'react'

import { LocationPinIcon } from '../icons'
import { SearchbarHistoryItem } from '../../../redux/searchbar/types'
import { ItemContainer } from './location-picker-items-styles'
import { HistoryItemTitle, ItemDescription, VerticalSeparator } from './location-picker-history-item-styles'
import { historyDateDisplay } from '../../../utils/miscellaneous-utils'
import { useTranslation } from '../../../hooks/locale'
import { getPersonsCount } from './utils'

interface Props {
    historyItem: SearchbarHistoryItem
    onSelect: (historyItem: SearchbarHistoryItem) => void
    isFocused: boolean
    onFocus: (item: SearchbarHistoryItem | null) => void
}

const LocationPickerHistoryItem: FC<Props> = ({ historyItem, onSelect, isFocused, onFocus }) => {
    const { search, arrival, departure, adults, children } = historyItem
    const { t } = useTranslation()

    const _handleMouseEnter = useCallback(() => onFocus(historyItem), [onFocus, historyItem])
    const _handleMouseLeave = useCallback(() => onFocus(null), [onFocus])

    const _handleItemClick: MouseEventHandler<HTMLLIElement> = useCallback(() => {
        onSelect(historyItem)
    }, [onSelect, historyItem])

    const persons = getPersonsCount(adults, children)
    const guestsLabel = !!persons && persons > 0 ? `, ${persons} ${persons <= 1 ? t('guest') : t('guests')}` : ''

    return (
        <ItemContainer
            onClick={_handleItemClick}
            isFocused={isFocused}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
        >
            <LocationPinIcon alt={t('mapIconText')} />
            <HistoryItemTitle>{search}</HistoryItemTitle>
            <VerticalSeparator />
            <ItemDescription>
                {!arrival || !departure ? t('flexiblePeriod') : historyDateDisplay(arrival, departure)}
                {guestsLabel}
            </ItemDescription>
        </ItemContainer>
    )
}

export default LocationPickerHistoryItem
