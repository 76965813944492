import styled, { css } from 'styled-components'

export interface ContainerProps {
    hasBorderTop?: boolean
    hasBorderBottom?: boolean
}

export const Container = styled.div<ContainerProps>(
    ({ hasBorderTop, hasBorderBottom, theme }) => css`
        width: ${theme.layout.fill};
        background-color: ${theme.colors.white};
        padding: ${theme.spacing.medium};

        ${!!hasBorderTop &&
        css`
            border-top: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        `};

        ${!!hasBorderBottom &&
        css`
            border-bottom: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        `};
    `,
)

export const InnerContainer = styled.div`
    display: flex;
    align-items: center;
`
