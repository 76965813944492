import React from 'react'

import { Container, HistoryContainer, HistoryContainerTitle } from './location-picker-items-styles'
import LocationPickerHistoryItem from './location-picker-history-item'
import { useTranslation } from '../../../hooks/locale'
import { LocationPickerCommonProps, LocationPickerRenderedComponentAddedProps } from './types'

type LocationPickerItemsProps<T extends {} = {}> = LocationPickerCommonProps<T> &
    Pick<LocationPickerRenderedComponentAddedProps<T>, 'historyItems' | 'focusedItem' | 'setFocusedItem'>

const LocationPickerItems = <T extends {} = {}>({
    locationItems,
    onLocationItemSelect,
    historyItems,
    onHistoryItemSelect,
    showLocationItems,
    locationItemComponent: LocationItemComponent,
    getLocationItemKey,
    focusedItem,
    setFocusedItem,
}: LocationPickerItemsProps<T>) => {
    const { t } = useTranslation()

    if (!showLocationItems && historyItems.length > 0) {
        return (
            <HistoryContainer>
                <HistoryContainerTitle>{t('recentSearches')}</HistoryContainerTitle>
                <Container>
                    {historyItems.map(historyItem => (
                        <LocationPickerHistoryItem
                            key={historyItem._time}
                            historyItem={historyItem}
                            onSelect={onHistoryItemSelect}
                            isFocused={historyItem === focusedItem}
                            onFocus={setFocusedItem}
                        />
                    ))}
                </Container>
            </HistoryContainer>
        )
    }

    return showLocationItems ? (
        <Container>
            {locationItems.map(item => (
                <LocationItemComponent
                    key={getLocationItemKey(item)}
                    item={item}
                    onSelect={onLocationItemSelect}
                    isFocused={item === focusedItem}
                    onFocus={setFocusedItem}
                />
            ))}
        </Container>
    ) : null
}

export default LocationPickerItems
