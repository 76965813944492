import styled from 'styled-components'

export const Container = styled.div<{ paddingTop?: string }>`
    height: ${props => props.theme.layout.fill};
    width: ${props => props.theme.layout.fill};
    display: grid;
    position: relative;
    ${props => !!props.paddingTop && `padding-top: ${props.paddingTop}`}
`

export const ImageContainer = styled.img<{ isLoading?: boolean; objectFit?: string; borderRadius?: string }>`
    position: absolute;
    top: 0;
    height: ${props => props.theme.layout.fill};
    border-radius: ${props => props.borderRadius};
    width: ${props => props.theme.layout.fill};
    display: ${props => (props.isLoading ? 'none' : 'unset')};
    object-fit: ${props => props.objectFit || 'cover'};
`

export const LoadingContainer = styled.img<{ borderRadius?: string; marginTop?: string }>`
    position: absolute;
    z-index: 2;
    bottom: 0;
    border-radius: ${props => props.borderRadius};
    height: ${props => props.theme.layout.fill};
    width: ${props => props.theme.layout.fill};
    object-fit: none;
    background-color: ${props => props.theme.colors.tfwBlack12};
    margin-top: -${props => props.marginTop};
`
