import { ChangeEventHandler, FocusEventHandler, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { SearchbarHistoryItem } from '../../../../../redux/searchbar/types'
import AnalyticsService from '../../../../../services/analytics-service'
import { OnHistoryItemSelect, OnLocationItemSelect } from '../../../../common/location-picker/types'
import { getSearchbarHistoryItemsState } from '../../../../../redux/searchbar'
import { CommonPageEventLabels } from '../../../../../services/analytics-service/labels'

export const useSearchbarLocationPicker = <LocationItem extends {} = {}>({
    onOpen,
    onClose,
    locationInput,
    onLocationInputChange,
    locationItems,
    getLocationItems,
    setLocationItems,
    onLocationItemSelect,
    onHistoryItemSelect,
}: {
    onOpen: () => void
    onClose: () => void
    locationInput: string
    onLocationInputChange: (input: string) => void
    locationItems: LocationItem[]
    getLocationItems: ((input: string) => LocationItem[]) | ((input: string) => Promise<LocationItem[]>)
    setLocationItems: (items: LocationItem[]) => void
    onLocationItemSelect: OnLocationItemSelect<LocationItem>
    onHistoryItemSelect: OnHistoryItemSelect
}) => {
    const showLocationItems = locationItems.length > 0

    const historyItems = useSelector(getSearchbarHistoryItemsState)

    const _handleLocationInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        event => {
            // Might be the case that the location input is not already open, so open it in case of change.
            onOpen()
            onLocationInputChange(event.target.value)
            if (event.target.value) {
                getLocationItems(event.target.value)
            } else {
                setLocationItems([])
            }
        },
        [getLocationItems, onLocationInputChange, onOpen, setLocationItems],
    )

    const _handleLocationInputFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
        if (locationInput) {
            getLocationItems(locationInput)
        }
        onOpen()
    }, [locationInput, onOpen, getLocationItems])

    const _handleLocationItemSelect = useCallback(
        (item: LocationItem) => {
            onLocationItemSelect(item)
            onClose()
        },
        [onLocationItemSelect, onClose],
    )

    const _handleHistoryItemSelect = useCallback(
        (historyItem: SearchbarHistoryItem) => {
            const itemIndex = historyItems.indexOf(historyItem)
            if (itemIndex !== -1) {
                AnalyticsService.trackEvent({
                    event: CommonPageEventLabels.searchSection.AUTO_SUGGEST,
                    eventValue: itemIndex + 1,
                })
            }

            onHistoryItemSelect(historyItem)
            onClose()
        },
        [historyItems, onHistoryItemSelect, onClose],
    )

    const _handleClear = useCallback(() => {
        onLocationInputChange('')
        setLocationItems([])
    }, [onLocationInputChange, setLocationItems])

    return {
        showLocationItems,
        onLocationInputChange: _handleLocationInputChange,
        onLocationInputFocus: _handleLocationInputFocus,
        onLocationItemSelect: _handleLocationItemSelect,
        onHistoryItemSelect: _handleHistoryItemSelect,
        onClear: _handleClear,
    }
}
