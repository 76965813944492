import { KeyboardEventHandler, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { SearchbarHistoryItem } from '../../../redux/searchbar/types'
import { getSearchbarHistoryItemsState } from '../../../redux/searchbar'
import { OnHistoryItemSelect, OnLocationInputSelect, OnLocationItemSelect } from './types'

export const useLocationPicker = <T extends {} = {}>({
    locationInput,
    onLocationInputSelect,
    showLocationItems,
    locationItems,
    onLocationItemSelect,
    onHistoryItemSelect,
    onKeyDown,
}: {
    locationInput: string
    onLocationInputSelect: OnLocationInputSelect
    showLocationItems: boolean
    locationItems: T[]
    onLocationItemSelect: OnLocationItemSelect<T>
    onHistoryItemSelect: OnHistoryItemSelect
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}) => {
    const [focusedItem, setFocusedItem] = useState<T | SearchbarHistoryItem | null>(null)

    const historyItems = useSelector(getSearchbarHistoryItemsState)

    const _focusNextItem = useCallback(() => {
        const items = showLocationItems ? locationItems : historyItems
        setFocusedItem(focusedItem => {
            if (focusedItem) {
                const itemIndex = items.indexOf(focusedItem as any)
                if (itemIndex !== -1 && itemIndex + 1 < items.length) {
                    return items[itemIndex + 1]
                }
            }
            if (items.length > 0) {
                return items[0]
            }
            return null
        })
    }, [showLocationItems, historyItems, locationItems])

    const _focusPreviousItem = useCallback(() => {
        const items = showLocationItems ? locationItems : historyItems
        setFocusedItem(focusedItem => {
            if (focusedItem) {
                const itemIndex = items.indexOf(focusedItem as any)
                if (itemIndex !== -1 && itemIndex - 1 >= 0) {
                    return items[itemIndex - 1]
                }
            }
            if (items.length > 0) {
                return items[items.length - 1]
            }
            return null
        })
    }, [showLocationItems, historyItems, locationItems])

    const _handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        event => {
            switch (event.key) {
                case 'Enter': {
                    if (focusedItem) {
                        if (showLocationItems) {
                            onLocationItemSelect(focusedItem as T)
                        } else {
                            onHistoryItemSelect(focusedItem as SearchbarHistoryItem)
                        }
                    } else {
                        onLocationInputSelect(locationInput)
                    }
                    break
                }
                case 'ArrowUp': {
                    // Prevent moving input cursor position, only move focus on item.
                    event.preventDefault()
                    _focusPreviousItem()
                    break
                }
                case 'ArrowDown': {
                    // Prevent moving input cursor position, only move focus on item.
                    event.preventDefault()
                    _focusNextItem()
                    break
                }
                default:
            }
            // This is necessary since we are passing down (almost) all props to the component that gets rendered.
            // If we remove this and the consumer of this component sends the `onKeyDown` prop, it will not be passed
            // down as we are passing down our custom `onKeyDown` prop. Hence, we make sure that we call this on our
            // own.
            onKeyDown?.(event)
        },
        [
            _focusNextItem,
            _focusPreviousItem,
            focusedItem,
            locationInput,
            onHistoryItemSelect,
            onKeyDown,
            onLocationInputSelect,
            onLocationItemSelect,
            showLocationItems,
        ],
    )

    return { focusedItem, setFocusedItem, historyItems, onKeyDown: _handleKeyDown }
}
