export const zIndexScale = {
    largeTags: {
        xxxxxxSmall: '100',
        xxxxxSmall: '200',
        xxxxSmall: '300',
        xxxSmall: '500',
        xxSmall: '1000',
        xSmall: '2000',
        small: '3000',
        medium: '5000',
        large: '10000',
        xlarge: '20000',
        xxLarge: '30000',
        xxxLarge: '40000',
        xxxxLarge: '50000',
        xxxxxLarge: '60000',
        xxxxxxLarge: '100000',
    },
    smallTags: {
        xxxxxSmall: '1',
        xxxxSmall: '2',
        xxxSmall: '3',
        xxSmall: '4',
        xsmall: '5',
        small: '6',
        medium: '7',
        large: '10',
        xLarge: '20',
        xxLarge: '30',
        xxxLarge: '40',
        xxxxLarge: '50',
        xxxxxLarge: '60',
    },
}

export const jsAppendedClassName = {
    body: {
        MORE_FILTER_CLICK: 'moreFilterClicked',
    },
}
