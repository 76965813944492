import React from 'react'

import { useOnClickOutside } from '../../../hooks/dom'
import LocationPickerItems from './location-picker-items'
import {
    LocationPickerCommonProps,
    LocationPickerExclusiveProps,
    LocationPickerRenderedComponentAddedProps,
    LocationPickerPassThroughProps,
} from './types'

type Props<T> = LocationPickerPassThroughProps &
    LocationPickerCommonProps<T> &
    LocationPickerRenderedComponentAddedProps<T> & {
        component: LocationPickerExclusiveProps<T>['widgetComponent']
        [prop: string]: unknown
    }

const Widget = <T extends {} = {}>({ component: Component, isOpen, onOpen, onClose, ...restProps }: Props<T>) => {
    const { ref: _widgetInputContainerRef } = useOnClickOutside(onClose)

    return (
        <Component {...restProps} isOpen={isOpen} onOpen={onOpen} onClose={onClose} inputRef={_widgetInputContainerRef}>
            <LocationPickerItems {...restProps} />
        </Component>
    )
}

export default Widget
